/* Reset default browser styles */
html, body, div, p, h1, h2, h3, h4, h5, h6, ul, ol, li, table, tr, th, td, form, input, button {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #282c34;
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column; /* Align children vertically */
    justify-content: flex-start; /* Align children at the start */
    align-items: center; /* Center horizontally */
    height: 100vh; /* Adjust height as needed */
    margin-top: 20vh; /* Add top margin */

    font-size: calc(10px + 1.8vmin);
}

.content {
    text-align: center; /* Horizontally center the content within the container */
}

.title {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.title h1 {
    margin-right: 10px;
    color: #ea600b;
}

.logo {
    width: 40px;
    height: 40px;
}

.search-container {
    display: flex;
    margin-bottom: 20px;
}

.search-input {
    flex: 1;
    padding: 10px;
    border: 0;
    border-radius: 5px 0 0 5px;
}

.search-input:focus {
    outline: none;
}

.search-button {
    padding: 10px 20px;
    background-color: #ea600b;
    color: #fff;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.loading-message {
    display: block;
    margin-bottom: 20px;
}

.results-table-title {
    margin-bottom: 20px;
}

.results-table {
    width: 100%;
    border-collapse: collapse;
}

.results-table th, .results-table td {
    padding: 4px 20px 0px 0px;
    text-align: center; /* Center the content of table cells */
    min-height: 32px;
}

.results-table td:first-child {
    text-align: left;
}

.external-link-icon {
    width: 24px;
    height: 24px;
}

/* Responsive styles */
@media (max-width: 600px) {
    .container {
        padding: 10px;
    }

    .title {
        font-size: 20px;
    }

    .search-container {
        flex-direction: column;
    }

    .search-input {
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .search-button {
        border-radius: 5px;
    }

    .results-table th, .results-table td {
        font-size: 14px;
    }
}
